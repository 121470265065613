<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <div class="report_start">
            <div class="startbox">
                <div class="batchbox">
                    <div class="title">{{ User.Year }}年 {{ User.ProvinceName }} 高考志愿模拟填报</div>
                    <div class="stepbox">
                        <div class="step">
                            <div class="box on">
                                <div class="s">1</div>
                                <div class="txt">选择批次</div>
                            </div>
                            <div class="border"></div>
                        </div>
                        <div class="step">
                            <div class="box">
                                <div class="s">2</div>
                                <div class="txt">智能填报</div>
                            </div>
                            <div class="border"></div>
                        </div>
                        <div class="step">
                            <div class="box">
                                <div class="s">3</div>
                                <div class="txt">生成志愿表</div>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="mark">
                        <span class="ti">
                            <i class="iconfont">&#xe60c;</i>选择批次
                        </span>
                        <!-- <span class="s">等效分:475 位次:137467</span> -->
                        
                        <span class="s">
                            {{ `成绩:${User.Score||'-'}` }}
                        </span>
                        <span v-if="RecommendBatch.Rank" class="s">
                            {{ `位次: ${RecommendBatch.Rank||'-'}` }}
                        </span>

                        <span v-if="User.SubjectType">
                            <span class="s">首选:{{User.SubjectType==1?'物理':'历史'}}</span>
                            <span v-if="User.SubjectList" class="s">再选:{{User.SubjectList.join(',')}}</span>
                        </span>
                        <span v-else>
                            <span v-if="User.SubjectList" class="s">{{User.SubjectList.join(',')}}</span>
                        </span>
                    </div>
                    <div class="batchlist">
                        <div class="head">
                            <div class="h">报考批次</div>
                            <div class="h">批次线</div>
                            <!-- <div class="h">备注</div> -->
                            <div class="h">填报</div>
                        </div>
                        <div class="list" v-for="(item, index) in RecommendBatch.RecommendBatchList" :key="index">
                            <div class="l">{{ item.BatchName+(item.BatchYear?'('+item.BatchYear+'年)':'') }}</div>
                            
                            <div class="l">{{ item.BatchScore }}</div>
                            <!-- <div class="l">{{ item.BatchRemark }}</div> -->
                            <div class="l">
                                <div v-if="item.BatchScore>User.Score" class="btn disabled">智能填报</div>
                                <div v-else class="btn" @click.prevent="ReportBtn(item)">智能填报</div>
                            </div>
                        </div>

                        <!-- 
                        <div class="head">
                            <div class="h">报考批次</div>
                            <div class="h">科类</div>
                            <div class="h">2024 批次线</div>
                            <div class="h">填报</div>
                        </div>
                        
                        <div class="list">
                            <div class="l">本一批</div>
                            <div class="l">理科</div>
                            <div class="l">539</div>
                            <div class="l"><div class="btn" @click.prevent="ReportBtn(1)">智能填报</div></div>
                        </div>
                        <div class="list">
                            <div class="l">本一批</div>
                            <div class="l">理科</div>
                            <div class="l">459</div>
                            <div class="l"><div class="btn" @click.prevent="ReportBtn(2)">智能填报</div></div>
                        </div>
                        <div class="list">
                            <div class="l">本一批</div>
                            <div class="l">理科</div>
                            <div class="l">150</div>
                            <div class="l"><div class="btn" @click.prevent="ReportBtn(3)">智能填报</div></div>
                        </div> -->
                    </div>
                    <div class="tips">
                        <div class="tt"><i class="iconfont">&#xe687;</i>温馨提示</div>
                        <div class="bz" v-html="GetMark(ProvinceObj.Notice)"></div></div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import { getStore, setStore, removeStore } from "@/utils/utils";
export default {
    components:{
        PHeader,
        PFooter
    },
    data() {
        return{
            main: {},

            ProvinceObj: {},
            RecommendBatch: {},

            User: {}
        }
    },
    mounted () {
        this.initial()
    },
    methods:{
        // 提示换行处理
        GetMark(con){
            if(con){
                return con.replace(/(\n)/g, '<br/>');
            }
            else{
                return '';
            }
        },
        // 填报
        ReportBtn(item){
            // this.$router.push("/recommend-index?id="+bid);
            setStore("SelectRecommendBatch", item)

            removeStore('ReportCheckDataObj')
            
            setStore('modifyFlag', false)
            
            this.$router.push("/recommend-index");
        },

        // 初始化数据
        initial() {
            let SReport = getStore('studentReport')

            if (!SReport) {
                this.$message.error('无填报成绩信息！')
                return
            }
            this.User = JSON.parse(SReport)
            
            let tempProvince = getStore('ExamProvince')
            if (!tempProvince) {
                this.$message.error('未获取省份列表！')
            }
            let temp = getStore('OrgProvince')

            if (!temp) {
                this.$message.error('未获取到省份数据！')
                return
            }
            this.ProvinceObj = JSON.parse(temp)

            this.getData()
        },
        
        // 获取数据
        getData() {
            API.Recommend.GetRecommend(this.User).then(res => {
                if (res.code == 0) {
                    this.RecommendBatch = res.data
                    setStore('RecommendBatch', this.RecommendBatch)
                } else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
.report_start{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .startbox{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .stepbox{
            padding-top: 40px;
            height: 80px;
            margin: 0 50px;
            .step:nth-child(1),
            .step:nth-child(2){
                width: calc((100% - 200px) / 2);
            }
            .step:nth-child(3){
                width: 180px;
            }
            .step{
                float: left;
                position: relative;
                .box{
                    display: inline-block;
                    width: 180px;
                    text-align: center;
                    background-color: #fff;
                    position: relative;
                    z-index: 2;

                }
                .s{
                    border: 2px solid #ddd;
                    border-radius: 30px;
                    background-color: #fff;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    display: inline-block;
                    color: #999;
                    font-size: 20px;
                }
                .box.on .s{
                    border: 2px solid rgba(var(--themecolor),0.8);
                    color: rgba(var(--themecolor),1);
                }
                .box.on .txt{
                    color: #555;
                }
                .txt{
                    display: inline-block;
                    color: #999;
                    font-size: 18px;
                    margin-left: 10px;
                    letter-spacing: 1px;
                }
                .border{
                    position: absolute;
                    top: 20px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #dad9d9;
                    z-index: 1;
                }
            }
        }
        .batchbox{
            margin: 20px 20px;
            background-color: #fff;
            border-radius: 5px;
            padding-bottom: 20px;
            .title{
                color: #444;
                font-size: 24px;
                letter-spacing: 2px;
                text-align: center;
                padding-top: 30px;
            }
            .mark{
                height: 50px;
                line-height: 50px;
                margin: 0 22px;
                .ti{
                    color: #666;
                    font-size: 16px;
                    i{
                        margin-right: 5px;
                    }
                }
                .s{
                    color: #666;
                    font-size: 14px;
                    margin-left: 30px;
                }
            }
            .batchlist{
                margin: 0 20px;
                border: 1px solid #ddd;
                border-radius: 5px;
                overflow: hidden;
                .head{
                    background-color: #f8f8f8;
                    border-bottom: 1px solid #ddd;
                    .h{
                        width: calc(100% / 3 - 1px);
                        display: inline-block;
                        height: 60px;
                        line-height: 60px;
                        border-left: 1px solid #ddd;
                        text-align: center;
                        color: #333;
                        font-size: 16px;
                    }
                    .h:first-child{
                        border-left: none;
                    }
                }
                .list{
                    border-bottom: 1px solid #ddd;
                    .l{
                        width: calc(100% / 3 - 1px);
                        display: inline-block;
                        height: 80px;
                        line-height: 80px;
                        border-left: 1px solid #ddd;
                        text-align: center;
                        color: #333;
                        font-size: 15px;
                        .btn{
                            width: 120px;
                            height: 38px;
                            line-height: 38px;
                            text-align: center;
                            display: inline-block;
                            border-radius: 5px;
                            cursor: pointer;
                            background-color: rgba(var(--themecolor),0.75);
                            color: #fff;
                        }
                        .btn:hover{
                            background-color: rgba(var(--themecolor),1);
                        }
                        .disabled {
                            background-color: #e0e0e0;
                            &:hover {
                                background-color: #e0e0e0;
                                cursor: default;
                            }
                        }
                    }
                    .l:first-child{
                        border-left: none;
                    }
                }
                .list:last-child{
                    border-bottom: none;
                }
            }
            .tips{
                margin: 30px 22px 20px;
                background-color: #f8f7f7;
                border: 1px dashed #ddd;
                padding: 20px 0;
                border-radius: 5px;
                .tt{
                    color: #c95f08;
                    font-size: 16px;
                    letter-spacing: 2px;
                    margin: 0 20px;
                    i{
                        font-size: 19px;
                        margin-right: 6px;
                    }
                }
                .bz{
                    color: #888;
                    font-size: 13px;
                    line-height: 24px;
                    margin: 8px 20px;
                }
            }
        }
    }
}
</style>